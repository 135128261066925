import classnames from 'classnames';
import React, { useEffect, useRef } from 'react';
import './modal.scss';

interface ModalProps {
  title?: string;
  open: boolean;
  onDismiss: () => void;
  children?: React.ReactNode;
  small?: boolean;
}
export const Modal = (props: ModalProps) => {
  const { title, open, onDismiss, children, small = false } = props;

  const dialogRef = useRef<HTMLDialogElement>(null);

  useEffect(() => {
    const dialog = dialogRef.current;
    if (!dialog) return;
    if (open) {
      dialog.showModal();
    } else {
      dialog.close();
    }
  }, [open]);

  return (
    <dialog
      ref={dialogRef}
      className={classnames('modal', {
        'modal--small': small,
      })}
      onCancel={onDismiss}
    >
      <div className='modal__header'>
        <div className='modal__title'>{!!title?.length && title}</div>
        <button className='modal__button' onClick={onDismiss}>
          Close
        </button>
      </div>
      {children}
    </dialog>
  );
};
