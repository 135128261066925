import { AxiosError, AxiosResponse } from 'axios';
import { useCallback } from 'react';
import { useMutation, UseMutationOptions } from 'react-query';
import { signIn, SignInRequest, SignInResponse } from '../api/auth';

export const useRequestSignIn = (
  options?: Omit<
    UseMutationOptions<
      AxiosResponse<SignInResponse, any>,
      AxiosError,
      SignInRequest,
      unknown
    >,
    'mutationFn'
  >
) => {
  const mutate = useCallback((params: SignInRequest) => {
    return signIn(params.email, params.password);
  }, []);
  return useMutation(mutate, options);
};
