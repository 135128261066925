import { AxiosResponse } from 'axios';
import { AUTH, axiosInst } from './common';

export interface SignInRequest {
  email: string;
  password: string;
}
export interface SignedInUser {
  name: string;
  email: string;
  id: string;
}
export interface SignInResponse {
  jwt: string;
  user: SignedInUser;
}
export const signIn = (
  email: string,
  password: string
): Promise<AxiosResponse<SignInResponse>> => {
  return axiosInst.post(AUTH, { email, password });
};
