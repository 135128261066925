import React, { useContext, useEffect, useState } from 'react';
import { LoadReadingPhraseModal, SelectRootConceptModal } from '../../modals';
import { InitialAppBar } from './InitialAppBar';
import { SessionModal } from '../../modals/SessionModal/SessionModal';
import { SessionExpirationContext } from '../../ReadingPhraseEditorProvider';
import './style.scss';

export const InitialPage = () => {
  const [openLoadPhrase, setOpenLoadPhrase] = useState(false);
  const [openCreatePhrase, setOpenCreatePhrase] = useState(false);
  const [openSessionModal, setOpenSessionModal] = useState(false);
  const { isExpired, setIsExpired } = useContext(SessionExpirationContext);

  useEffect(() => {
    if (isExpired) {
      setOpenSessionModal(true);
      setIsExpired(undefined);
    }
  }, [isExpired]);

  return (
    <>
      <div className='initial-page'>
        <InitialAppBar />
        <div className='initial-page__button-list'>
          <div className='initial-page__button-container'>
            <img
              className='initial-page__button'
              onClick={() => setOpenLoadPhrase(true)}
              src={require('../../assets/open-phrase.png')}
            />
            <div className='initial-page__button-text'>Open Reading Phrase</div>
          </div>
          <div className='initial-page__button-container'>
            <img
              className='initial-page__button'
              onClick={() => setOpenCreatePhrase(true)}
              src={require('../../assets/create-phrase.png')}
            />
            <div className='initial-page__button-text'>
              Create new Reading Phrase
            </div>
          </div>
        </div>
      </div>
      <LoadReadingPhraseModal
        open={openLoadPhrase}
        onDismiss={() => setOpenLoadPhrase(false)}
      />
      <SelectRootConceptModal
        open={openCreatePhrase}
        onDismiss={() => setOpenCreatePhrase(false)}
      />
      <SessionModal
        open={openSessionModal}
        onDismiss={() => setOpenSessionModal(false)}
      />
    </>
  );
};
