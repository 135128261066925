import { useEffect } from 'react';
import { HPACS_URL, REPORT_EDITOR_URL } from '../api/common';
import { useLoadReadingPhrase } from './readingPhraseHook';

export const useExternalOpener = () => {
  const setPhraseId = useLoadReadingPhrase();
  useEffect(() => {
    window.addEventListener('message', async (e) => {
      if (e.origin !== HPACS_URL && e.origin !== REPORT_EDITOR_URL) return;

      switch ((e.data || {}).event) {
        case 'GARNET_LOGIN': {
          const auth = e.data?.auth;
          const user = e.data?.user;

          localStorage.setItem('AUTH', auth);
          localStorage.setItem('USER', user);
          break;
        }
        case 'GARNET_SET_PHRASE': {
          const phraseId = e.data?.phraseId;
          if (phraseId != null)
            setPhraseId(phraseId);
          break;
        }
        default:
          break;
      }
    });
    window.opener?.postMessage({ event:'GARNET_LOADED' }, HPACS_URL);
    window.opener?.postMessage({ event:'GARNET_LOADED' }, REPORT_EDITOR_URL);
  }, []);
};
