import React from 'react';
import { ReadingPhraseTree, TextTemplateEditor, TreeContextProvider, TreeMenuContextProvider } from 'spinel';
import { AppBar } from './AppBar';
import { RightSideBar } from './RightSideBar';
import './style.scss';

export const EditingPage = () => {
  return (
    <div className='editing-page'>
      <AppBar />
      <TreeContextProvider>
        <TreeMenuContextProvider>
          <div className='editing-page__content'>
            <div className='editing-page__content-left'>
              <div className='editing-page__content-section'>
                <div className='editing-page__content-section-title'>
                  Text Template
                </div>
                <TextTemplateEditor isEditMode />
              </div>
              <div className='editing-page__content-section'>
                <div className='editing-page__content-section-title'>
                  Reading Phrase Tree
                </div>
                <ReadingPhraseTree />
              </div>
            </div>
            <RightSideBar />
          </div>
        </TreeMenuContextProvider>
      </TreeContextProvider>
    </div>
  );
};
