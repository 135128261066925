import { pki, pss, md, mgf } from 'node-forge';

const spinellaPubKey = pki.publicKeyFromPem(atob(process.env.REACT_APP_SPINELLA_PUB_KEY_BASE64));
// TODO: FIXME: REMOVE THIS KEY
const ourPrivKey = pki.privateKeyFromPem(atob(process.env.REACT_APP_HPACS_PRIV_KEY_BASE64));

const PSS = pss.create({
  md: md.sha256.create(),
  mgf: mgf.mgf1.create(md.sha256.create()),
  saltLength: 32
});

export function getSpinellaToken(sid: string, uid: string, username: string, ts?: number): string {
  const data = `${uid}|${username}|${ts ?? +new Date()}`;
  const encryptedData = spinellaPubKey.encrypt(data, 'RSA-OAEP', {
    md: md.sha256.create(),
    mgf1: {
      md: md.sha256.create()
    }
  });
  const hash = md.sha256.create().update(data, 'utf8').digest().data;
  const sign = ourPrivKey.sign(md.sha256.create().update(hash, 'raw'), PSS);
  return `${btoa(sid)}.${btoa(encryptedData)}.${btoa(hash)}.${btoa(sign)}`;
}
