import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  situationWithExplicitContext,
  useIsConceptInAttributeRange,
  useSnomedChildren,
} from '../../hooks/snowstorm';
import { LoadContextWrapperInformation, ReadingPhraseEditorContext } from '../../ReadingPhraseEditorProvider';
import { getCodeValueByNodeId } from '../../utils/utils';

export const SelectContextWrapperDropDown = () => {
  const { setPhrase, setSubTree } = useContext(LoadContextWrapperInformation);
  const { draft: phraseDraft } = useContext(ReadingPhraseEditorContext);
  const { data: contexts } = useSnomedChildren(situationWithExplicitContext);
  const [contextConceptId, setContextConceptId] = useState('');
  const filteredContexts = useMemo(
    () => contexts?.filter((context) => context.descendantCount !== 0) ?? [],
    [contexts]
  );

  const readingPhraseTree = phraseDraft?.readingPhraseTree;
  const rootId = readingPhraseTree?.root ?? '';
  const rootConceptValue = getCodeValueByNodeId(readingPhraseTree, rootId);
  const originalRootId = readingPhraseTree?.originalRootId;
  const originalRootConceptValue = getCodeValueByNodeId(readingPhraseTree, originalRootId);
  const contextConceptIds = filteredContexts.map(context => context.conceptId);
  const { data: filteredContextWrapper } =
    useIsConceptInAttributeRange(contextConceptIds, originalRootConceptValue ?? rootConceptValue ?? '');
  const [validContextWrapperList, setValidContextWrapperList] = useState<string[]>([]);

  // 처음 로딩했을때 드롭다운을 알맞은 값으로 초기화
  useEffect(() => {
    if (originalRootId?.length && rootConceptValue) {
      setContextConceptId(rootConceptValue);
    } else {
      setContextConceptId('');
    }
  }, [readingPhraseTree]);

  // subTree draft 저장
  useEffect(() => {
    if (rootConceptValue) {
      const isContextWrapper =
        filteredContexts.map(context => context.conceptId).includes(rootConceptValue);
      if (readingPhraseTree && (!isContextWrapper || originalRootId?.length)) {
        setSubTree?.(readingPhraseTree, originalRootId ?? rootId);
      }
    }
  }, [readingPhraseTree]);

  const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const contextConceptId = e.target.value;
    setContextConceptId(contextConceptId);
    setPhrase?.(contextConceptId);
  };

  useEffect(() => {
    if (rootConceptValue) {
      const isContextWrapper =
        filteredContexts.map(context => context.conceptId).includes(rootConceptValue);
      if (!originalRootId?.length) {
        if (isContextWrapper) {
          return;
        }
      }
      setValidContextWrapperList(filteredContextWrapper ?? []);
    }
  }, [rootConceptValue, originalRootId, filteredContextWrapper]);

  return (
    <select
      onChange={onChange}
      value={contextConceptId}
      disabled={!validContextWrapperList.length}
    >
      <option value={''}>No Context</option>
      {validContextWrapperList &&
        validContextWrapperList.map((conceptId) => (
          filteredContexts.map(context => (
            context.conceptId === conceptId &&
                <option key={context.conceptId} value={context.conceptId} >
                  {context.pt.term}
                </option>
          ))
        ))
      }
    </select>
  );
};
