import axios from 'axios';

export const HPACS_URL = process.env.REACT_APP_HPACS_URL;
export const REPORT_EDITOR_URL = process.env.REACT_APP_REPORT_EDITOR_URL;
export const READING_PHRASE = '/phrase';
export const AUTH = '/auth/login/v1';

export const axiosInst = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
});

export interface PageSort {
  empty?: boolean;
  sorted?: boolean;
  unsorted?: boolean;
}
export interface Pageable {
  offset: number,
  pageNumber: number;
  pageSize: number;
  paged: boolean;
  sort: PageSort,
  unpaged: boolean;
}
export interface Page<T> {
  content: T[];
  empty: boolean;
  totalElements: number;
  totalPages: number;
  last: boolean;
  size: number;
  number: number;
  numberOfElements: number;
  first: boolean;
  pageable: Pageable,
  sort: PageSort,
}

export interface ErrorResponse {
  code: string;
  message: string;
  detail?: string;
}