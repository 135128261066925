import { useCallback } from 'react';
import { useQuery, UseQueryOptions } from 'react-query';
import { SnowstormClient } from 'snowstorm-api';

export const SCT_SEARCH = 'sct_search';
export const CONCEPT_CHILDREN = 'concept_children';
export const SCT_CONTEXT_WRAPPER_ECLS = 'CONTEXT_WRAPPER_ECLS';

export const client = new SnowstormClient(process.env.REACT_APP_BACKEND_URL ?? '');
export const branch = 'MAIN';
export const rangeRefsetId = '723562003';
export const isAId = '116680003';
export const situationWithExplicitContext = '243796009';

export const useSnomedChildren = (conceptId: string) => {
  const fetch = useCallback(() => {
    return client.get('/browser/{branch}/concepts/{conceptId}/children', {
      branch,
      conceptId,
      includeDescendantCount: true,
    });
  }, [conceptId]);
  return useQuery([SCT_SEARCH, CONCEPT_CHILDREN, conceptId], fetch, {
    enabled: !!conceptId.length,
  });
};
const getAttributes = async (contextConceptId: string) => {
  return await client.get('/mrcm/{branch}/domain-attributes', {
    branch,
    parentIds: [contextConceptId],
  });
};

const getEclsByRanges = async (contextConceptId: string) => {
  const attributes = await getAttributes(contextConceptId);

  const ranges = await Promise.all(attributes.items.filter(item => item.active)
    .map(item => item.conceptId)
    .filter(attrbuteId => attrbuteId !== isAId)
    .map(attributeId => {
      return client.get('/browser/{branch}/members', {
        branch,
        referencedComponentId: attributeId,
        referenceSet: rangeRefsetId,
        active: true,
      });
    }));
  return ranges.map(range => range?.items[0]?.additionalFields?.rangeConstraint as string ?? '');
};

export const useContextWrapperEcls = (
  contextConceptId: string,
  options?: Omit<UseQueryOptions<unknown, unknown, string[], string[]>, 'queryKey'>,
) => {
  const fetch = useCallback(async () => {
    return await getEclsByRanges(contextConceptId);
  }, [contextConceptId]);

  return useQuery([SCT_CONTEXT_WRAPPER_ECLS, contextConceptId], fetch, {
    ...options,
    enabled: !!contextConceptId.length,
  });
};

export const useIsConceptInAttributeRange = (
  contextConceptIds: string[],
  conceptId: string,
  options?: Omit<UseQueryOptions<unknown, unknown, string[], string[]>, 'queryKey'>
) => {
  const fetch = useCallback(async () => {
    const filteredContextWrapperIds: string[] = [];
    const eclResultsByContext = await Promise.all(contextConceptIds.map(async contextConceptId => {
      const ecls = await getEclsByRanges(contextConceptId);
      return await Promise.all(ecls.map(ecl => client.get('/{branch}/concepts', {
        branch,
        ecl,
        conceptIds: [conceptId],
        activeFilter: true,
        termActive: true,
      })));
    }));
    eclResultsByContext.map((eclResults, idx) => {
      const index = eclResults.findIndex(result => !!result.items.length);
      if (index !== -1) {
        filteredContextWrapperIds.push(contextConceptIds[idx]);
      }
    });
    return filteredContextWrapperIds;
  }, [contextConceptIds, conceptId]);

  return useQuery([SCT_CONTEXT_WRAPPER_ECLS, ...contextConceptIds, conceptId], fetch, {
    ...options,
    enabled: !!conceptId.length,
  });
};
