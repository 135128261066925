import React, { useContext } from 'react';
import { useExternalOpener } from '../../hooks/external';
import { ReadingPhraseEditorContext } from '../../ReadingPhraseEditorProvider';
import { EditingPage } from './EditingPage';
import { InitialPage } from './InitialPage';

export const MainPage = () => {
  const { draft: phrase } = useContext(ReadingPhraseEditorContext);
  useExternalOpener();

  return phrase?.readingPhraseTree.root ? <EditingPage /> : <InitialPage />;
};
