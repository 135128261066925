import classNames from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import { useUser } from '../../hooks/authHook';
import {
  LoadReadingPhraseModal,
  LoginModal,
  LogoutModal,
  SaveReadingPhraseModal,
  SelectRootConceptModal,
} from '../../modals';
import { SelectContextWrapperDropDown } from './SelectContextWrapperDropDown';
import { ReadingPhraseEditorContext } from '../../ReadingPhraseEditorProvider';
import './style.scss';

export const AppBar = () => {
  const { draft: currentReadingPhrase, setDraft } = useContext(ReadingPhraseEditorContext);
  const getUsername = useUser();
  const [username, setUsername] = useState<string | undefined>();
  const [openCreatePhrase, setOpenCreatePhrase] = useState(false);
  const [openLoadPhrase, setOpenLoadPhrase] = useState(false);
  const [openSavePhrase, setOpenSavePhrase] = useState(false);
  const [editingPhraseId, setEditingPhraseId] = useState<string | undefined>();
  const [openSaveMenu, setOpenSaveMenu] = useState(false);
  const [openLoginModal, setOpenLoginModal] = useState(false);
  const [openLogoutModal, setOpenLogoutModal] = useState(false);
  const isSaveable = currentReadingPhrase?.title.length && !!username;
  const isEditable = !!currentReadingPhrase?.id && username === currentReadingPhrase.user?.email;

  useEffect(() => {
    setUsername(getUsername()?.email);
  }, [openLoginModal, openLogoutModal]);

  const onClickSaveNew = () => {
    setEditingPhraseId(undefined);
    setOpenSavePhrase(true);
  };
  const onClickSave = () => {
    if (isEditable) {
      setEditingPhraseId(currentReadingPhrase.phraseId);
      setOpenSavePhrase(true);
    } else {
      onClickSaveNew();
    }
  };

  return (
    <>
      <div className='app-bar'>
        <div className='app-bar__phrase-info'>
          <span className='app-bar__phrase-info-label'>Title:</span>
          <input className='app-bar__phrase-title__input'
            value={currentReadingPhrase?.title ?? ''}
            onChange={(e) => {
              setDraft((d) =>
                d ?
                  {
                    ...d,
                    title: e.target?.value,
                  } :
                  undefined
              );
            }}
          />
          <SelectContextWrapperDropDown />
        </div>
        <div
          className={classNames('app-bar__user-info', {
            'app-bar__user-info--need-login': !username,
          })}
        >
          {username ?? '(Login to publish reading phrase)'}
        </div>
        {!username ? (
          <button
            className='app-bar__button'
            onClick={() => setOpenLoginModal(true)}
          >
            Login
          </button>
        ) : (
          <button
            className='app-bar__button'
            onClick={() => setOpenLogoutModal(true)}
          >
            Logout
          </button>
        )}
        <button
          onClick={() => setOpenLoadPhrase(true)}
          className='app-bar__button'
        >
          Open
        </button>
        <button
          onClick={() => setOpenCreatePhrase(true)}
          className='app-bar__button'
        >
          New
        </button>
        <div
          className='app-bar__save-button-container'
          onMouseEnter={
            isSaveable && isEditable ? () => setOpenSaveMenu(true) : undefined
          }
          onMouseLeave={() => setOpenSaveMenu(false)}
        >
          <button
            onClick={onClickSave}
            disabled={!isSaveable}
            className='app-bar__button app-bar__save-button'
          >
            저장하기
          </button>
          {openSaveMenu && (
            <button
              className='app-bar__button app-bar__save-button app-bar__save-as-new-button'
              disabled={!isSaveable}
              onClick={onClickSaveNew}
            >
              다른 이름으로 저장하기
            </button>
          )}
        </div>
      </div>
      <LoginModal
        open={openLoginModal}
        onDismiss={() => setOpenLoginModal(false)}
      />
      <LogoutModal
        open={openLogoutModal}
        onDismiss={() => setOpenLogoutModal(false)}
      />
      <SelectRootConceptModal
        open={openCreatePhrase}
        onDismiss={() => setOpenCreatePhrase(false)}
      />
      <LoadReadingPhraseModal
        open={openLoadPhrase}
        onDismiss={() => setOpenLoadPhrase(false)}
      />
      <SaveReadingPhraseModal
        open={openSavePhrase}
        phraseId={editingPhraseId}
        onDismiss={() => setOpenSavePhrase(false)}
      />
    </>
  );
};
