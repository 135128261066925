import React from 'react';
import './modal.scss';

interface ModalActionProps {
  children?: React.ReactNode;
}
export const ModalAction = (props: ModalActionProps) => {
  const { children } = props;
  return (
    <div className='modal__action'>
      {children}
    </div>
  );
};
