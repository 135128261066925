import React, { useEffect, useState } from 'react';
import { useUser } from '../../hooks/authHook';
import { LoginModal, LogoutModal } from '../../modals';

export const InitialAppBar = () => {
  const getUsername = useUser();
  const [username, setUsername] = useState<string | undefined>();
  const [openLoginModal, setOpenLoginModal] = useState(false);
  const [openLogoutModal, setOpenLogoutModal] = useState(false);
  useEffect(() => {
    setUsername(getUsername()?.email);
  }, [getUsername, openLoginModal, openLogoutModal]);

  return (
    <>
      <div className='app-bar'>
        <div className='app-bar__title'>
          Healthhub SNOWPHRASE
        </div>
        {!username ? (
          <button
            className='app-bar__button'
            onClick={() => setOpenLoginModal(true)}
          >
            Login
          </button>
        ) : (
          <button
            className='app-bar__button'
            onClick={() => setOpenLogoutModal(true)}
          >
            Logout
          </button>
        )}
      </div>
      <LoginModal
        open={openLoginModal}
        onDismiss={() => setOpenLoginModal(false)}
      />
      <LogoutModal
        open={openLogoutModal}
        onDismiss={() => setOpenLogoutModal(false)}
      />
    </>
  )

};