import { AxiosError } from 'axios';
import React, { useState } from 'react';
import { useSignIn } from '../../../hooks/authHook';

interface LoginFormProps {
  onSuccess?: () => void;
  onError?: (e: AxiosError) => void;
}
export const LoginForm = (props: LoginFormProps) => {
  const { onSuccess, onError } = props;
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const signIn = useSignIn({ onSuccess, onError });

  return (
    <form
      className='login-form'
      onSubmit={e => {
        e.preventDefault();
        signIn({ email: username, password });
      }}
    >
      <div className='login-form__field'>
        <label className='login-form__field-label'>Email</label>
        <input
          className='login-form__input login-form__input--id'
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
      </div>
      <div className='login-form__field'>
        <label className='login-form__field-label'>Password</label>
        <input
          className='login-form__input login-form__input--password'
          type='password'
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <button type='submit' className='login-form__button'>
        LOGIN
      </button>
    </form>
  );
};
