import React from 'react';
import { Modal, ModalContent } from '../Modal';
import './session-modal.scss';


interface SessionModalProps {
  open: boolean;
  onDismiss: () => void;
}

export const SessionModal = (props: SessionModalProps) => {
  const { open, onDismiss } = props;
  
  return (
    <Modal title='session expired' open={open} onDismiss={onDismiss} small>
      <ModalContent>
        <div className='session-modal'>
          <label className='session-modal__label'>
            세션이 만료되었습니다. 초기화면으로 돌아갑니다.
          </label>
        </div>
      </ModalContent>
    </Modal>
  );
};
