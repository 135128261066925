import React, { useEffect, useState } from 'react';
import { LoginForm } from './LoginForm/LoginForm';
import './login-modal.scss';
import { Modal, ModalContent } from '../Modal';
import { AxiosError } from 'axios';

interface LoginModalProps {
  open: boolean;
  onDismiss: () => void;
}
export const LoginModal = (props: LoginModalProps) => {
  const { open, onDismiss } = props;

  const [errorMessage, setErrorMessage] = useState('');
  const onError = (e: AxiosError) => {
    if (e.response?.status === 401) {
      setErrorMessage('Failed to login, check your email / password');
    } else {
      setErrorMessage('Failed to login, possible server failure');
    }
  };
  useEffect(() => {
    setErrorMessage('');
  }, [open]);

  return (
    <Modal title='Login to HPACS' open={open} onDismiss={onDismiss} small>
      <ModalContent>
        <div className='login-modal'>
          <LoginForm
            onSuccess={onDismiss}
            onError={onError}
          />
          {!!errorMessage.length && (
            <div className='login-modal__error-message'>{errorMessage}</div>
          )}
        </div>
      </ModalContent>
    </Modal>
  );
};
