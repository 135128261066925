import React, { useState } from 'react';
import { Resizable } from 're-resizable';
import './style.scss';
import classNames from 'classnames';
import { AttributeMenu, MeasurementMenu, MetaInformationEditor, PropertyMenu, ValueSetListMenu } from 'spinel';

enum TabMenu {
  Attribute,
  Property,
  Measurement,
  ValueSetList,
  MetaInformation,
}
export const RightSideBar = () => {
  const [currentTab, setCurrentTab] = useState<TabMenu>(TabMenu.Attribute);
  const [width, setWidth] = useState(600);
  return (
    <Resizable
      enable={{
        top: false,
        right: false,
        bottom: false,
        left: true,
        topRight: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false,
      }}
      size={{
        height: '100%',
        width,
      }}
      onResizeStop={(e, direction, ref, d) => {
        setWidth((prevWidth) => Math.max(prevWidth + d.width, 480));
      }}
    >
      <div className='right-side-bar'>
        <div className='right-side-bar__tab'>
          <button
            className={classNames('right-side-bar__tab-menu', {
              'right-side-bar__tab-menu--current':
                currentTab === TabMenu.Attribute,
            })}
            onClick={() => setCurrentTab(TabMenu.Attribute)}
          >
            SCT Attribute
          </button>
          <button
            className={classNames('right-side-bar__tab-menu', {
              'right-side-bar__tab-menu--current':
                currentTab === TabMenu.Property,
            })}
            onClick={() => setCurrentTab(TabMenu.Property)}
          >
            non-SCT Property
          </button>
          <button
            className={classNames('right-side-bar__tab-menu', {
              'right-side-bar__tab-menu--current':
                currentTab === TabMenu.Measurement,
            })}
            onClick={() => setCurrentTab(TabMenu.Measurement)}
          >
            Measurement
          </button>
          <button
            className={classNames('right-side-bar__tab-menu', {
              'right-side-bar__tab-menu--current':
                currentTab === TabMenu.ValueSetList,
            })}
            onClick={() => setCurrentTab(TabMenu.ValueSetList)}
          >
            ValueSet List
          </button>
          <button
            className={classNames('right-side-bar__tab-menu', {
              'right-side-bar__tab-menu--current':
                currentTab === TabMenu.MetaInformation,
            })}
            onClick={() => setCurrentTab(TabMenu.MetaInformation)}
          >
            Meta Information
          </button>
        </div>
        <div className='right-side-bar__content'>
          {currentTab === TabMenu.Attribute ? (
            <AttributeMenu />
          ) : currentTab === TabMenu.Property ? (
            <PropertyMenu />
          ) : currentTab === TabMenu.Measurement ? (
            <MeasurementMenu />
          ) : currentTab === TabMenu.ValueSetList ? (
            <ValueSetListMenu />
          ) : (
            <MetaInformationEditor />
          )}
        </div>
      </div>
    </Resizable>
  );
};
