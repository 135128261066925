import { useEffect, useRef, useState } from "react";
import equal from 'deep-equal';

const DEFAULT_CACHE_SIZE = 30;
export const useCachedState = <T>(init?: T, cacheSize: number = DEFAULT_CACHE_SIZE) => {
  const [state, setState] = useState<T | undefined>(init);
  const [states, _setStates] = useState<T[]>(init ? [init] : []);
  const [index, _setIndex] = useState(0);
  const indexRef = useRef(index);
  const statesRef = useRef(states);
  const setIndex = (i: number) => {
    indexRef.current = i;
    _setIndex(i);
  };
  const setStates = (arr: T[]) => {
    statesRef.current = arr;
    _setStates(arr);
  }

  useEffect(() => {
    if (states.length > index) {
      if (!equal(state, states[index])) {
        setState(states[index]);
      }
    }
  }, [states, index]);

  const onCommit = (s: T) => {
    const copy = states.slice(Math.max(0, index - cacheSize + 1), index + 1);
    copy.push(s);
    setStates(copy);
    setIndex(copy.length - 1);
  };

  const undo = () => {
    setIndex(Math.max(0, indexRef.current - 1));
  };
  const redo = () => {
    setIndex(Math.min(statesRef.current.length - 1, indexRef.current + 1));
  };
  const reset = (s?: T) => {
    setState(s);
    setStates(s ? [s] : []);
    setIndex(0);
  };

  return {
    state,
    setState,
    onCommit,
    undo,
    redo,
    reset,
  };
};